<template lang="pug">
.FcwImageTextCta(:class="classes")
  .FcwImageTextCta__image
    FImage(
      v-bind="image"
      height="100%"
      width="100%"
    )
  .FcwImageTextCta__text(:class="textClasses")
    h3(v-if="title") {{ title }}
    p {{ text }}
    FcwButtonLink(
      v-if="buttonText"
      :location="buttonLocation"
    ) {{ buttonText }}
</template>

<style lang="stylus">
.FcwImageTextCta
  display flex
  justify-content space-between
  align-items center

  > * + *
    margin-left rem(64)
    margin-right 0

  &--reversed
    flex-direction row-reverse

    > * + *
      margin-left 0
      margin-right rem(64)

  +media-down('sm')
    flex-direction column
    align-items initial

    > * + *
      margin-left 0
      margin-top rem(32)

    &--reversed
      > * + *
        margin-right 0

.FcwImageTextCta__image
  flex 0 0 50%

.FcwImageTextCta__text
  flex 0 0 40%
  display flex
  flex-direction column
  align-items flex-start
  padding rem(16)
  white-space pre-line

  &--centered
    justify-content center

  h3
    color var(--color--primary)

  > * + *
    margin-top rem(32)

  +media-down('sm')
    flex 0 0 50%
</style>

<script setup lang="ts">
import type { FImageProps } from '@fifteen/design-system-vue';
import type { RouteLocationRaw } from 'vue-router';

export interface FcwImageTextCtaProps {
  /**
   * Image of the component
   */
  image: FImageProps;
  /**
   * Title of the content
   */
  title?: string;
  /**
   * Text content of the component
   */
  text: string;
  /**
   * Text of the CTA
   */
  buttonText?: string;
  /**
   * Location of the CTA
   */
  buttonLocation?: RouteLocationRaw;
  /**
   * Reverse the display. If true, displays text first
   */
  reversed?: boolean;
}

const props = withDefaults(defineProps<FcwImageTextCtaProps>(), {
  title: '',
  buttonText: '',
  buttonLocation: '',
});

const classes = computed<VueClasses>(() => ({
  'FcwImageTextCta--reversed': props.reversed,
}));

const textClasses = computed<VueClasses>(() => ({
  'FcwImageTextCta__text--centered': !props.title,
}));
</script>
